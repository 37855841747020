@media screen and (min-width: 800px ) and (max-height:1500px) {
    .login-container {
        min-height: 80vh;

        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        align-self: center;
    }
    .welcome-title{
        font-family: "LeroyMerlin", sans-serif;
        font-weight: 600;
        margin:0;
        color: var(--text-beta-secondary, #333);
    }
    .welcome-brand{
        font-family: "LeroyMerlin", sans-serif;
        font-weight: 600;
        margin:0;
        color: #46a610;
   
    }

    .logo-input-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        max-width: 600px;
        min-width: 500px;
    }

    .logo-input-container .mc-password-input {

        width: 100%;
    }

    .login-logo {
        width: 400px;
    }

    .login-button {
        position: absolute;
        bottom: 40px;
        max-width: 330px;
    }
    .login-input{
        margin-bottom: 50px !important;

    }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 800px) {
    .welcome-title{
        font-family: "LeroyMerlin", sans-serif;
        font-weight: 600;
        
        color: var(--text-beta-secondary, #333);
    }
    .welcome-brand{
        font-family: "LeroyMerlin", sans-serif;
        font-weight: 600;
        margin:0;
        color: #46a610;
   
    }
    .login-container {
        min-height: 80vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        align-self: center;
    }

    .logo-input-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin-top: -15%;
        width: 85%; 
        height: 224;
    }

    .logo-input-container .mc-password-input {
        width: 100%;
    }

    .login-logo {
        width: 200px;
        margin-top: 100px;
        margin-bottom: 50px;
    }

    .login-input{
        margin-bottom: 30px !important;
  
    }

    .login-button {
        position: absolute;
        bottom: 40px;
        max-width: 75%;
    }
}


@media screen and (min-height: 1500px) {
    .welcome-title{
        font-family: "LeroyMerlin", sans-serif;
        font-weight: 600;
        
        font-size: 3rem;
        color: var(--text-beta-secondary, #333);
    }
    .welcome-brand{
        font-family: "LeroyMerlin", sans-serif;
        font-weight: 600;
        margin:0;
        color: #46a610;
        font-size: 3rem;
    }
    .login-container {
        min-height: 80vh;
 
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        align-self: center;
    }

    .logo-input-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 60%;
        height: 252;
    }


    .logo-input-container .mc-password-input {

        width: 100%;
    }

    .login-logo {
        margin-top: 200px;
        margin-bottom: 150px;
        width: 400px;
    }

    .login-button {
        position: absolute;
        bottom: 40px;
        max-width: 330px;
        height: 80px;
        font-size: 2rem !important;

    }
    .login-input{
        margin-bottom: 50px !important;
        font-size: 2rem !important;
        height:80px
    }

    .mc-password-input__button{
        margin-bottom: 50px !important;
        font-size: 1.5rem !important;
        height:80px !important
    }
}

