.mainHistory {
  padding-top: 75px;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0px;
}

.titleHistory{
  color: var(--greyscale-800, #333);
text-align: center;
font-size: 20px;
font-family: LeroyMerlin;
font-style: normal;
font-weight: 400;
line-height: 24px;
margin-bottom: 60px;
}

.HistoryTopDiv{
  background-color: white;
  padding-top: 1px;
  height: 86px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.SearchInputDiv {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  background-color: white;
}

.SearchBar{
  position: fixed;
  background-color: white;
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.stateless {
  font-size: 16px;
  font-family: LeroyMerlin;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  margin-top: 50%;
}

.cart-stateless {
  width: 30%;
  height: 30%;
}

@media screen and (max-width: 768px) {
  .cart-stateless {
    width: 50%;
    height: 50%;
  }
}

@media screen and (min-height: 1200px) {
  .stateless {
    font-size: 40px;
    font-family: LeroyMerlin;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center; /* Center vertically */
    margin-top: 50%;
  }

  .cart-stateless {
    width: 30%;
    height: 30%;
  }
}


