@media screen and (min-height: 375px) {
  .product-item-search {
    width: 85vw;
    max-height: 312px;
    min-height: 64px;
  }
}

@media screen and (min-height: 1200px) {
  .product-item-search {
    width: 90.5vw !important;
    max-height: 364px;
    height: 154px;
    min-height: 364px;
  }
  .product-item-search__title {
    font-size: 20px !important;
    width: 350px !important;
    font-weight: 400;
  }
  .product-item-search__date {
    font-size: 30px !important;
    width: 350px !important;
    font-weight: 400;
  }
  .product-item-search__price {
    font-size: 25px !important;
    width: 111px !important;
    font-weight: 400;
  }
  .product-item-search__header {
    display: flex;
    flex-direction: column;
    margin-left: 20px !important;
  }

  .product-item-search__image img {
    border: #666666 1px solid;
  }
}

@media screen and (min-height: 1300px) {
  .product-item-search {
    width: 88.5vw;
    max-height: 240px !important;
    height: 240px !important;
    min-height: 200px !important;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.product-item-search {
  width: 94.5vw;
  min-height: 64px;
}

.product-item-search-button {
  outline: 0;
  border: none;
  background-color: #ffffff;
}

.product-item-search__image {
  width: 10%;
  z-index: 999;
  justify-content: center;
  align-items: center;
  display: flex;
}

.product-item-search__image img {
  width: 250px;
  z-index: 999;
  height: 250px;
  max-height: 10vh;
  max-width:  10vh;
}

.product-item-search__details {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.product-item-search__header {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.product-item-search__title {
  color: var(--text-beta-secondary, #333);
  font-size: 12px;
  font-family: "LeroyMerlin", sans-serif;
  margin: 4px;
  margin-left: 2px;
  display: flex;
  width: 30;

  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

.product-item-search__date {
  display: flex;
  width: 54px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--primary-01600-default, #000000);
  font-size: 10px;
  font-family: "LeroyMerlin", sans-serif;
}

.product-item-search__time {
  display: flex;
  width: 54px;

  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--text-beta-primary, #000);
  text-align: right;
  font-size: 10px;
  font-family: "LeroyMerlin", sans-serif;
}

.product-item-search__price {
  display: flex;
  width: 70px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--text-beta-secondary, #333);
  text-align: center;
  font-size: 14px;
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 600;
}

