.mainCart {
  padding-top: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  top: 0px;
}
  .bottomCart {
    position: fixed;
    bottom: 0px;
    height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 0;
    background-color: white;
    margin: 0px;
    padding: 0px;
  }
  .cart_icon_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .cart_quantity {
    color: var(--text-beta-secondary, #333);
    text-align: center;
    font-size: 16px;
    font-family: LeroyMerlin;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin-left: 5px;
  }
  .cart_total {
    color: var(--text-beta-secondary, #333);
    text-align: center;
    font-size: 16px;
    font-family: LeroyMerlin;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .cart_total_number {
    color: var(--text-beta-secondary, #333);
    text-align: center;
    font-size: 16px;
    font-family: LeroyMerlin;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }

  @media screen and (min-width: 800px ) and (max-height:1500px) {
    .buttons__div {
      display: flex;
      align-items: center;
   justify-content: flex-end;
      width: 90vw;
      gap: 30px;
    }
    .cart-button{
      width: 330px;
    }
  }
  
  /* On screens that are 600px or less, set the background color to olive */
  @media screen and (max-width: 800px) {
    .buttons__div {
      display: flex;
      align-items: center;
      justify-content:center;
      width: 90vw;
      gap: 30px;
    }
    .cart-button{
      
      width: 80%;
    }
  }
  
  
  @media screen and (min-height: 1500px) {
    .buttons__div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 90vw;
      gap: 30px;
    }
    .cart-button{
      width: 330px;
    }
  }
  
  
  
 

  .quantity_price_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90vw;
  }

  .divider_cart {
    height: 0.3px;
    width: 100vw;
    background-color: black;
  }


.SearchInputDiv {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  background-color: white;
}

.SearchBar{
  position: fixed;
  background-color: white;
  padding-top: 5px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
 
  
}